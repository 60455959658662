import React, { useState } from "react";
import "./Feedback.css";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useStoreActions } from "easy-peasy";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
    },
  },
}));
export default function Issue(props) {
  const classes = useStyles();
  const [issues, setIssues] = useState("");
  const addFeedback = useStoreActions((actions) => actions.addFeedback);

  const onTextChange = (e) => {
    setIssues(e.target.value);
  };

  const submitFeedback = () => {
    const payload = {
      user_id: "0001",
      type: props.type,
      feedback: issues,
    };

    addFeedback(payload);
    props.toggle("done");
  };

  return (
    <div className="feedback-wrapper-issues-main">
      <div className="feedback-wrapper-issues">
        <ArrowBackIcon
          style={{ color: "grey", cursor: "pointer" }}
          onClick={() => props.toggle("menu")}
        />
        <span className="feedback-title">
          {props.icon}
          {props.title}
        </span>
        <CloseIcon
          style={{ color: "grey", cursor: "pointer" }}
          onClick={() => props.toggle("menu")}
        />
      </div>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          onChange={(e) => onTextChange(e)}
          className="inputRounded"
          placeholder={props.placeholder}
          autoFocus
          style={{ width: "280px", borderWidth: "10px" }}
          id="outlined-basic"
          variant="outlined"
          multiline
          rows={2}
          value={issues}
          name="issues"
          inputProps={{ maxLength: 200 }}
        />
      </form>
      <Button
        disabled={issues.length >= 1 ? false : true}
        style={{ boxShadow: "none", marginTop: "9px", borderRadius: "20px" }}
        variant="contained"
        color="primary"
        onClick={submitFeedback}
      >
        Send Feedback
      </Button>
    </div>
  );
}
