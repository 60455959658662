import React, { useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Splide } from "@splidejs/react-splide";
import VivaQuizCard from "./VivaQuizCard";
import Fab from "@material-ui/core/Fab";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import SpeedIcon from "@material-ui/icons/Speed";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import AddViva from "./add-vivas/AddViva";
import cards from "../../../assets/cards.png";
import { useWindowSize } from "../../../Hooks/useWindowSize";
export default function FlashCards() {
  const questions = useStoreState((state) => state.questions);
  const [isOpen, setIsOpen] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);
  const [replay, setReplay] = useState(1);
  const [speed, setSpeed] = useState(70);
  const [interval, setInterval] = useState(300);
  const size = useWindowSize();

  console.log(size.height * 0.3);
  const [options, setOptions] = useState({
    width: "90%",
    rewind: replay,
    perPage: 1,
    gap: "2rem",
    direction: "ttb",
    height: "40%",
    speed: speed,
    focus: "center",
    pagination: false,
    trimSpace: false,
    autoplay: autoPlay,
    autoHeight: true,
    interval: interval,
  });

  const renderQuizCards = questions.map((question, index) => {
    return (
      <VivaQuizCard
        isColor={replay == index ? true : false}
        test={autoPlay}
        question={question}
      />
    );
  });

  const doThis = () => {
    setAutoPlay(!autoPlay);
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div
      style={{
        marginTop: "0px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {questions.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "30rem",
            alignItems: "center",
            marginTop: "98px",
            flexDirection: "column",
          }}
        >
          <img draggable={false} style={{ width: "50%" }} src={cards}></img>
          <span style={{ color: "#9D9FA0" }} className="progress-text">
            no cards found, add now!
          </span>
        </div>
      ) : (
        <Splide
          options={options}
          onMoved={(splide, newIndex) => {
            setReplay(newIndex);
          }}
        >
          {renderQuizCards}
        </Splide>
      )}

      <Fab
        data-tut="add_q_button"
        onClick={() => setIsOpen(true)}
        color="primary"
        aria-label="add"
        style={{
          backgroundColor: "#5e2bfa",
          boxShadow: "none",
          position: "fixed",
          top: "48%",
          right: "10px",
        }}
      >
        <AddIcon style={{ height: "30px", width: "30px", color: "white" }} />
      </Fab>
      <AddViva modal={isOpen} toggle={toggleModal} />

      {/* <div className="vivas-settings">
        <Fab color="primary" aria-label="add" onClick={() => doThis()}>
          <PlayCircleFilledIcon />
        </Fab>
        <Fab color="primary" aria-label="add">
          <FastRewindIcon />
        </Fab>
        <Fab color="primary" aria-label="add">
          <SpeedIcon />
        </Fab>
        <Fab color="primary" aria-label="add">
          <AccessAlarmIcon />
        </Fab>
      </div> */}
    </div>
  );
}
