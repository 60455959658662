import React from "react";
import ColoredLine from "../../common/ColoredLine";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
export default function ViewFiles(props) {
  const acceptedFileItems = props.files.map((file) => (
    <div className="file-item-content-wrapper" key={file.path}>
      <span className="file-upload-name">{file.path}</span>
      <span className="file-upload-size">
        {(parseInt(file.size) / 1024).toFixed(1) + " KB"}{" "}
        <CheckCircleIcon style={{ color: "#5BC15B", marginLeft: "10px" }} />
      </span>
    </div>
  ));

  const fileRejectionItems = props.rejections.map(({ file, errors }) => {
    return (
      <div className="file-item-content-wrapper" key={file.path}>
        <span className="file-upload-name">{file.path}</span>
        <span className="file-upload-size">
          {(parseInt(file.size) / 1024).toFixed(1) + " KB"}{" "}
          <ErrorIcon style={{ color: "#ff5252", marginLeft: "10px" }} />
        </span>
        {errors.map((e) => (
          <span
            style={{ color: "#ff5252", fontWeight: "400", fontSize: 15 }}
            key={e.code}
          >
            {e.code === "file-too-large"
              ? "File is larger than 2.5mb"
              : e.message}
          </span>
        ))}
      </div>
    );
  });

  let drawerClasses = "view-files-main";
  if (props.show) {
    drawerClasses = "side-drawer";
  }
  return (
    <div className={drawerClasses}>
      <span className="view-file-header">Selected Files</span>
      <ColoredLine color="#C9C9C9" width="100px"></ColoredLine>
      <div style={{ overflow: "scroll", scrollbarWidth: "none" }}>
        {fileRejectionItems}
        {acceptedFileItems}
      </div>
    </div>
  );
}
