import React from "react";
import "./HowTo.css";
import { motion } from "framer-motion";
import Siriwave from "react-siriwave";
export default function HowTo() {
  return (
    <motion.div
      className="howto-main "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {" "}
    </motion.div>
  );
}
