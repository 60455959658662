import React from "react";
import Paper from "@material-ui/core/Paper";
import {
  Chart,
  PieSeries,
  Tooltip,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation, EventTracker, Palette } from "@devexpress/dx-react-chart";
import {
  schemeCategory10,
  schemeAccent,
  schemeDark2,
  schemePaired,
  schemePastel1,
  schemePastel2,
  schemeSet1,
  schemeSet2,
  schemeSet3,
} from "d3-scale-chromatic";
import "../SearchEngine.css";
export default function SearchResultChart(props) {
  const data = [
    { lecture_name: "Russia", no_of_slides: 1 },
    { lecture_name: "Canada", no_of_slides: 1 },
    { lecture_name: "USA", no_of_slides: 1 },
    { lecture_name: "China", no_of_slides: 1 },
    { lecture_name: "Brazil", no_of_slides: 1 },
    { lecture_name: "Australia", no_of_slides: 1 },
    { lecture_name: "India", no_of_slides: 1 },
    { lecture_name: "Others", no_of_slides: 1 },
  ];
  return (
    <Paper
      style={{
        borderRadius: "20px",
        boxShadow:
          "0 1px 5px 0 rgba(0, 0, 0, 0.4), 0 1px 1px 0 rgba(0, 0, 0, 0)",
      }}
      className="search-result-stats-chart-paper"
    >
      <Chart
        height={props.chartHeight * 0.25}
        className="search-result-stats-chart-in"
        data={props.tops.length === 0 ? data : props.top}
      >
        <EventTracker />
        <Palette scheme={schemeSet2} />
        <Tooltip />

        <PieSeries
          outerRadius={1}
          valueField="no_of_slides"
          argumentField="lecture_name"
        />

        <Animation />
      </Chart>
    </Paper>
  );
}
