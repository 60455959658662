import React from "react";
import "./Footer.css";
import GTranslateIcon from "@material-ui/icons/GTranslate";

import GitHubIcon from "@material-ui/icons/GitHub";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
export default function Footer() {
  return (
    <div className="footer-main">
      <div className="home-footer-dashboard">
        <span className="by-text-dashboard">
          Developed by{" "}
          <a
            style={{ textDecoration: "none", color: "black" }}
            href="https://github.com/dulajkavinda"
          >
            <span className="home-name-dashboard">Dulaj Kavinda</span>
          </a>
        </span>

        <div className="home-socials-footer">
          <FacebookIcon
            fontSize="normal"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://www.facebook.com/dulaj.kavind/";
            }}
            style={{ cursor: "pointer", color: "#9d9fa0" }}
          />
          <GitHubIcon
            fontSize="normal"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://github.com/dulajkavinda";
            }}
            style={{ cursor: "pointer", color: "#9d9fa0" }}
          />
          <InstagramIcon
            fontSize="normal"
            onClick={(e) => {
              e.preventDefault();
              window.location.href =
                "https://www.instagram.com/dulaj.kaavinda/";
            }}
            style={{ cursor: "pointer", color: "#9d9fa0" }}
          />
        </div>
      </div>
    </div>
  );
}
