import React, { useEffect } from "react";
import "./Home.css";
import logo from "../assets/logo2.png";
import tagline from "../assets/tagline.png";
import Button from "@material-ui/core/Button";
import GitHubIcon from "@material-ui/icons/GitHub";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useWindowSize } from "../Hooks/useWindowSize";
import firebase from "firebase";

const config = {
  apiKey: "AIzaSyBNsw5EepAuxqQZ1N11xwaZBTdoMYxLGwA",
  authDomain: "openbook-588ec.firebaseapp.com",
};

if (!firebase.apps.length) {
  try {
    firebase.initializeApp(config);
  } catch (err) {
    console.error("Firebase initialization error raised!", err.stack);
  }
}

export default function Home() {
  const setUser = useStoreActions((actions) => actions.setUser);
  const setUserData = useStoreActions((actions) => actions.setUserData);

  const size = useWindowSize();

  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: "/upload",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setUser(!!user);
        if (!!user) {
          setUserData(user);
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);
  return (
    <motion.div
      className="home-main"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <img
        draggable={false}
        className="home-logo"
        style={{ width: size.height * 0.55, paddingTop:"3em" }}
        src={logo}
      ></img>
      <div className="home-login-buttons">
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
      <div className="home-main-bottom">
        <img
          style={{ width: size.height * 0.55 }}
          draggable={false}
          src={tagline}
        ></img>
        <span className="home-disclaimer">education purpose only</span>
        <div className="home-footer">
          <span className="by-text">
            Developed by{" "}
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="https://github.com/dulajkavinda"
            >
              <span className="home-name">Dulaj Kavinda</span>
            </a>
          </span>

          <div className="home-socials">
            <span className="home-find-me">Find me On | </span>

            {/* <FacebookIcon
              fontSize="large"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "https://www.facebook.com/dulaj.kavind/";
              }}
              style={{ cursor: "pointer" }}
            /> */}
            <GitHubIcon
              fontSize="large"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "https://github.com/dulajkavinda";
              }}
              style={{ cursor: "pointer" }}
            />
            <InstagramIcon
              fontSize="large"
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  "https://www.instagram.com/dulaj.kaavinda/";
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
