import React, { useState, useEffect } from "react";
import SearchBar from "material-ui-search-bar";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import SeachResult from "./SeachResult";
import { FixedSizeList } from "react-window";
import SeachResultInfo from "./search-result-info/SeachResultInfo";
import SearchResultChart from "./search-result-info/SearchResultChart";
import Snackbar from "@material-ui/core/Snackbar";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Tour from "reactour";
import { motion } from "framer-motion";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert from "@material-ui/lab/Alert";
import "./SearchEngine.css";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useWindowSize } from "../../../Hooks/useWindowSize";
const accentColor = "linear-gradient(to right, #1c8f9e, #5cb7b7)";
const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 100,
    fontSize: "12px",
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    width: "100%",
    fontSize: 16,
    padding: "15px 47px 15px 20px",
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.4), 0 1px 1px 0 rgba(0, 0, 0, 0)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
export default function SearchEngine(props) {
  const size = useWindowSize();
  const subjectsData = useStoreState((state) => state.subjectsData);
  const timeResponse = useStoreState((state) => state.timeResponse);
  const [placeHolder, setplaceHolder] = useState(
    "Type Keywords and press ENTER (min 2 characters)"
  );
  const isNewUserSearch = useStoreState((state) => state.isNewUserSearch);
  const [searchInput, setSearchInput] = React.useState("");
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchResult, setSearchResult] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);
  const [topLecs, setTopLecs] = React.useState([]);
  const [topLecsCheck, setTopLecsCheck] = React.useState([]);
  const setisNewUserSearch = useStoreActions(
    (actions) => actions.setisNewUserSearch
  );
  const [selectedSubjectData, setSelectedSubjectData] = React.useState(
    subjectsData.length > 0 ? subjectsData[0] : null
  );
  const getSlideLengthAll = () => {
    function sum(arr) {
      var sum = 0;
      for (var index = 0; index < arr.length; index++) {
        sum += arr[index];
      }
      return sum;
    }
    let slides = 0;
    const result = subjectsData[0].content.map((data) => {
      return data.slides.length + slides;
    });
    return sum(result);
  };
  const [selectedSubject, setSelectedSubject] = React.useState({
    total_documents:
      subjectsData.length > 0 ? subjectsData[0].content.length : "0",
    total_slides:
      subjectsData.length > 0 && subjectsData[0].content.length > 0
        ? getSlideLengthAll()
        : "0",
  });

  const [age, setAge] = React.useState(
    subjectsData.length > 0 ? subjectsData[0].subject_name : "None"
  );
  let subjectNameArray = [];

  if (subjectsData != null) {
    subjectNameArray = subjectsData.map((subject) => {
      return {
        value: subject.subject_name,
        label: subject.subject_name,
      };
    });
  }
  const classes = useStyles();
  const [alignment, setAlignment] = React.useState("left");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
    var found = subjectsData.filter(function (item) {
      return item.subject_name === event.target.value;
    });
    setSelectedSubjectData(found[0]);

    const getSlideLength = () => {
      function sum(arr) {
        var sum = 0;
        for (var index = 0; index < arr.length; index++) {
          sum += arr[index];
        }
        return sum;
      }
      let slides = 0;
      const result = found[0].content.map((data) => {
        return data.slides.length + slides;
      });
      return sum(result);
    };
    setSelectedSubject({
      total_documents: found[0].content.length,
      total_slides: getSlideLength(),
    });
  };

  function renderRow() {
    return (
      <div>
        {searchResult.map((data) => {
          return (
            <SeachResult
              key={data.pageno + data.lecture_name}
              slideData={data.slide_text}
              slideno={data.pageno + 1}
              lectureName={data.lecture_name}
              searchTerm={
                refresh ? searchKeyword.split(" ") : searchInput.split(" ")
              }
            />
          );
        })}
      </div>
    );
  }

  const search = () => {
    if (searchInput.length > 1) {
      if (selectedSubjectData != null) {
        let resultArr = [];

        selectedSubjectData.content.map((data) => {
          data.slides.map((text, index) => {
            let keyword = searchInput.toLocaleLowerCase().replace(/ /g, "");
            let content_lower = text.toLowerCase().replace(/ /g, "");
            let myReg = new RegExp(".*" + keyword + ".*");
            if (content_lower.match(myReg)) {
              let result = {
                lecture_name: data.lecture_name,
                pageno: index,
                slide_text: text,
              };

              resultArr.push(result);
            }
          });
        });

        setSearchResult(resultArr);
        setSearchKeyword(searchInput);
        function groupByKey(array, key) {
          return array.reduce((hash, obj) => {
            if (obj[key] === undefined) return hash;
            return Object.assign(hash, {
              [obj[key]]: (hash[obj[key]] || []).concat(obj),
            });
          }, {});
        }

        let topLecutres = [];

        let grouped = groupByKey(resultArr, "lecture_name");

        for (const [key, value] of Object.entries(grouped)) {
          let topLecutre = {
            lecture_name: key,
            no_of_slides: value.length,
          };
          topLecutres.push(topLecutre);
        }
        topLecutres.sort(function (a, b) {
          return parseInt(b.no_of_slides) - parseInt(a.no_of_slides);
        });
        setTopLecs(topLecutres);
        setTopLecsCheck(topLecutres);
      }
    } else {
      setSearchInput("");
      setplaceHolder("minimum 2 characters required");
    }
  };

  const handleSearch = (newValue) => {
    setSearchInput(newValue);
    if (refresh === false) {
      search();
    }
  };

  const refreshToggle = () => {
    setRefresh(!refresh);
    setSearchInput("");
    setSearchResult([]);
    setOpen(true);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [isTourOpen, setIsTourOpen] = React.useState(
    isNewUserSearch === "done" || isNewUserSearch === false ? false : true
  );
  const timeout = (ms) => new Promise((res) => setTimeout(res, ms));
  const steps = [
    {
      selector: "",
      disableFocusLock: false,
      stepInteraction: false,
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🔎 Welcome to the Seach Engine!
            </span>
            <span style={{ textAlign: "center" }}>
              Let’s have a look on how to search through the uploaded contents
            </span>
          </span>
        );
      },
      actionBefore: async () => {
        await timeout(5000);
      },
    },
    {
      stepInteraction: false,
      selector: '[data-tut="search_select"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              📕 Selecting Subjects
            </span>
            <span style={{ textAlign: "center" }}>
              First select the subject you want to search for
            </span>
          </span>
        );
      },
    },
    {
      stepInteraction: false,
      selector: '[data-tut="search_bar"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🔍 Search
            </span>
            <span style={{ textAlign: "center" }}>
              And then enter the keyword which you wanted to search for and
              press ENTER to start searching
            </span>
          </span>
        );
      },
    },
    {
      stepInteraction: false,
      selector: '[data-tut="search_toggle"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🐙 Realtime Search
            </span>
            <span style={{ textAlign: "center" }}>
              By enablig this feature you can search realtime rather than
              pressing the enter key
            </span>
          </span>
        );
      },
    },
    {
      action: () => {
        setIsTourOpen(false);
        setisNewUserSearch(false);
        localStorage.setItem("search", JSON.stringify("dones"));
      },
      stepInteraction: false,
      selector: "",
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🐙 Done
            </span>
            <span style={{ textAlign: "center" }}>Thats it for Search!!</span>
          </span>
        );
      },
    },
  ];

  return (
    <motion.div
      className="main-search "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
          autoHideDuration={3000}
        >
          <Alert onClose={handleClose} severity={refresh ? "info" : "success"}>
            {refresh ? "Realtime Search Disabled" : "Realtime Search Activated"}
          </Alert>
        </Snackbar>
        <div className="search-inputs">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "76%",
              marginRight: "10px",
            }}
          >
            <SearchBar
              placeholder={placeHolder}
              data-tut="search_bar"
              className="search-bar"
              value={searchInput}
              onChange={(newValue) => {
                handleSearch(newValue);
              }}
              onRequestSearch={() => search()}
            />

            <ToggleButtonGroup
              data-tut="search_toggle"
              style={{ marginRight: "1.5%" }}
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton
                style={{
                  color: refresh ? "" : "#2ecc71",
                  backgroundColor: refresh ? "" : "#D6F2E2",
                }}
                onClick={() => refreshToggle()}
                selected={refresh}
                value="justify"
                aria-label="justified"
              >
                <Tooltip
                  title="Enables realtime searching"
                  classes={{ tooltip: classes.customWidth }}
                >
                  <AutorenewIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <FormControl style={{ width: "24%" }}>
            <Select
              data-tut="search_select"
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={age}
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              <MenuItem disabled={true} value="None">
                Select a Subject
              </MenuItem>
              {subjectNameArray.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="search-result-row-2">
          <div className="search-result-wrapper">
            <div className="search-result-list">
              <FixedSizeList
                style={{ margin: "0px", padding: "0px", overflow: "scroll" }}
                height={size.height * 0.65}
                width={"100%"}
                itemSize={46}
                itemCount={1}
              >
                {renderRow}
              </FixedSizeList>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "24%",
              height: "100%",
            }}
          >
            <SeachResultInfo info={selectedSubject} top={topLecs} />
            <div style={{ marginTop: "20px" }}>
              <SearchResultChart
                chartHeight={size.height}
                top={topLecs}
                tops={topLecsCheck}
              />
            </div>
          </div>
        </div>
      </div>
      <Tour
        showCloseButton={false}
        rounded={5}
        accentColor={accentColor}
        steps={steps}
        isOpen={isTourOpen}
      />
    </motion.div>
  );
}
