import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import AddViva from "./add-vivas/AddViva";

import { useStoreActions, useStoreState } from "easy-peasy";

import VivaTabs from "./vivas-tab/VivaTabs";

import "./Vivas.css";
export default function Vivas() {
  const [isOpen, setIsOpen] = useState(false);

  const getQuestions = useStoreActions((actions) => actions.getQuestions);

  const userData = useStoreState((state) => state.userData);

  useEffect(() => {
    getQuestions(userData.uid);
  }, []);

  return (
    <motion.div
      className="vivas-main "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="viva-audio">
        <VivaTabs />
      </div>
    </motion.div>
  );
}
