import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import "./UploadDashboard.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStoreActions, useStoreState } from "easy-peasy";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LectureList from "./LectureList";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
}));

export default function SubjectItem(props) {
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const classes = useStyles();
  const [opens, setOpens] = React.useState(false);
  const [value, setValue] = React.useState("Dione");
  const userData = useStoreState((state) => state.userData);

  const handleClickListItem = () => {
    setOpens(true);
  };

  const handleCloses = (newValue) => {
    setOpens(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const delete_subject = useStoreActions((actions) => actions.deleteSubject);

  const deleteSubject = (subject) => {
    const payload = {
      subject_name: subject,
      user_id: userData.uid,
    };
    delete_subject(payload);
    handleClickSnack();
    handleClose();
  };

  return (
    <div className="subject-container">
      <div>
        <ConfirmationDialogRaw
          classes={{
            paper: classes.paper,
          }}
          id="ringtone-menu"
          keepMounted
          open={opens}
          onClose={handleCloses}
          value={value}
          lectures={props.subject.content}
          subjectname={props.subject.subject_name}
        />
        <Dialog
          PaperProps={{
            style: { borderRadius: 20 },
          }}
          open={open}
          onClose={handleCloses}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span className="subject-item-dialog-text">
                Are you sure you want to delete{" "}
                <span className="subject-item-dialog-text-sub">
                  {props.subject.subject_name}
                </span>
                ?
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              NOPE
            </Button>
            <Button
              onClick={() => deleteSubject(props.subject.subject_name)}
              style={{ color: "red" }}
              autoFocus
            >
              DELETE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="subject-item" data-tut="subject_item">
        <div className="subject-info-wrapper">
          <div
            className="subjectItemStatus"
            style={{ backgroundColor: "#2ecc71" }}
          />
          <div className="subject-item-content-wrapper">
            <span className="subject-name">{props.subject.subject_name} </span>
            <span className="subject-lecture-count">
              {props.subject.content.length} Lectures
            </span>
          </div>
        </div>

        <div
          style={{
            minWidth: "270px",
          }}
        >
          <Button
            onClick={handleClickListItem}
            variant="contained"
            style={{
              borderRadius: 10,
              backgroundColor: "#2196f3",
              padding: "6px 20px",
              boxShadow: "none",
              color: "white",
            }}
          >
            <span className="button-text-item">View Lectures</span>
          </Button>
          <Button
            variant="contained"
            style={{
              boxShadow: "none",
              borderRadius: 10,
              backgroundColor: "#ff5252",
              padding: "6px 20px",
              marginLeft: "10px",
              marginRight: "10px",
              color: "white",
            }}
            onClick={handleClickOpen}
          >
            <span className="button-text-item">Remove</span>
          </Button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message="Subject Deleted"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnack}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleOk = () => {
    onClose(value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <span style={{ fontWeight: "bold" }}>{props.subjectname}</span>{" "}
        <span style={{ fontWeight: "bold", color: "#8B8B8B" }}>Documents</span>
      </DialogTitle>
      <DialogContent dividers>
        {props.lectures.map((lecture) => (
          <LectureList subjectname={props.subjectname} lecture={lecture} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          DONE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
