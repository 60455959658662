import { createStore, thunk, action, persist } from "easy-peasy";
import * as subjectApi from "../api/subjectApi";
import firebase from "firebase";
const store = createStore(
  persist({
    isNewUserUpload: false,
    isNewUserSearch: false,
    isNewUserViva: false,
    serverStatus: "OK",
    isLogged: false,
    isLoading: false,
    subjectsData: null,
    isModalOpen: false,
    subjectNames: [],
    questions: [],
    userData: {},
    uploadStatus: "",
    setUploadingStatus: action((state, data) => {
      state.uploadStatus = data;
    }),
    setUser: action((state, data) => {
      state.isLogged = data;
    }),
    setisNewUserUpload: action((state, data) => {
      state.isNewUserUpload = data;
    }),
    setisNewUserSearch: action((state, data) => {
      state.isNewUserSearch = data;
    }),
    setisNewUserViva: action((state, data) => {
      state.isNewUserViva = data;
    }),
    nullUser: action((state) => {
      state.subjectsData = null;
      state.userData = {};
      state.isLogged = false;
      localStorage.setItem("viva", JSON.stringify(null));
      localStorage.setItem("search", JSON.stringify(null));
      localStorage.setItem("upload", JSON.stringify(null));
    }),
    setUserData: action((state, data) => {
      state.userData = data;
    }),
    signOut: thunk(async (actions, payload) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          actions.setUser(false);
          actions.nullUser();
        })
        .catch((error) => {});
    }),
    setLoadingTrue: action((state) => {
      state.isLoading = true;
    }),
    setModalOpen: action((state, data) => {
      state.isModalOpen = data;
    }),
    setServerStatus: action((state, data) => {
      state.serverStatus = data;
    }),
    setLoadingFalse: action((state) => {
      state.isLoading = false;
    }),
    setSubjectData: action((state, data) => {
      state.subjectsData = data;
    }),
    setQuestions: action((state, data) => {
      state.questions = data;
    }),
    setSubjectNames: action((state, data) => {
      // state.subjectNames = subjectNameArray;
    }),
    getQuestions: thunk(async (actions, payload) => {
      subjectApi
        .getQuestions({ user_id: payload })
        .then((res) => {
          actions.setQuestions(res.data[0].quiz);
        })
        .catch((err) => {});
    }),
    getSubjects: thunk(async (actions, payload) => {
      subjectApi
        .getSubjects({ user_id: payload })
        .then((res) => {
          if (res.status === 200) {
            actions.setSubjectData(res.data);
            actions.setServerStatus("OK");
            actions.setSubjectNames();
          } else {
            actions.setServerStatus("BAD");
            actions.setSubjectData([]);
          }
        })
        .catch((err) => {});
    }),
    deleteSubject: thunk(async (actions, payload) => {
      const deletedSubject = {
        user_id: payload.user_id,
        subject_name: payload.subject_name,
      };
      subjectApi
        .deleteSubject(deletedSubject)
        .then((res) => {
          if (res.data === "deleted") {
            actions.getSubjects(payload.user_id);
          }
        })
        .catch((err) => {});
    }),
    addSubjectToDb: thunk(async (actions, payload) => {
      actions.setUploadingStatus("storing");
      subjectApi
        .addSubjectToDb({ payload })
        .then((res) => {
          actions.getSubjects(payload.user_id);
          actions.setUploadingStatus("success");
          setTimeout(() => {
            actions.setModalOpen(false);
          }, 1000);
          setTimeout(() => {
            actions.setLoadingFalse();
            actions.setModalOpen(false);
          }, 2000);
        })
        .catch((err) => {
          actions.setUploadingStatus("error");
          setTimeout(() => {
            actions.setModalOpen(false);
          }, 1000);
          setTimeout(() => {
            actions.setLoadingFalse();
          }, 1500);
        });
    }),
    addFeedback: thunk(async (actions, payload) => {
      subjectApi
        .addFeedback(payload)
        .then((res) => {})
        .catch((err) => {});
    }),
    addQuestion: thunk(async (actions, payload) => {
      subjectApi
        .addQuestion(payload)
        .then((res) => {
          actions.getQuestions(payload.user_id);
        })
        .catch((err) => {});
    }),
    deleteQuestion: thunk(async (actions, payload) => {
      subjectApi
        .deleteQuestion(payload)
        .then((res) => {
          actions.getQuestions(payload.user_id);
        })
        .catch((err) => {});
    }),
    renameLecture: thunk(async (actions, payload) => {
      subjectApi
        .renameLecture(payload)
        .then((res) => {
          actions.getSubjects(payload.user_id);
        })
        .catch((err) => {});
    }),
    deleteLecture: thunk(async (actions, payload) => {
      subjectApi
        .deleteLecture(payload)
        .then((res) => {
          actions.getSubjects(payload.user_id);
        })
        .catch((err) => {});
    }),
    addSubjects: thunk(async (actions, payload) => {
      actions.setLoadingTrue();
      actions.setUploadingStatus("loading");
      subjectApi
        .addSubject(payload.formData)
        .then((res) => {
          if (res.status === 200) {
            const newSubject = {
              subject_name: res.data.subject_name,
              data: res.data.content,
              user_id: payload.user_id,
            };
            actions.addSubjectToDb(newSubject);
          } else {
            actions.setUploadingStatus("error");
            setTimeout(() => {
              actions.setModalOpen(false);
            }, 1000);
            setTimeout(() => {
              actions.setLoadingFalse();
            }, 1500);
          }
        })
        .catch((err) => {
          actions.setUploadingStatus("error");
          setTimeout(() => {
            actions.setModalOpen(false);
          }, 1000);
          setTimeout(() => {
            actions.setLoadingFalse();
          }, 1500);
        });
    }),
  })
);

export default store;
