import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FlashCards from "../FlashCards";
import SpeachRecognition from "../audio-recognition/SpeachRecognition";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { useStoreActions, useStoreState } from "easy-peasy";

import Tour from "reactour";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
  },
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const setisNewUserViva = useStoreActions(
    (actions) => actions.setisNewUserViva
  );

  const isNewUserViva = useStoreState((state) => state.isNewUserViva);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [isTourOpen, setIsTourOpen] = React.useState(
    isNewUserViva === "done" || isNewUserViva === false ? false : true
  );
  const timeout = (ms) => new Promise((res) => setTimeout(res, ms));
  const steps = [
    {
      selector: "",
      disableFocusLock: false,
      stepInteraction: false,
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🔖 Here’s the Viva section.
            </span>
            <span style={{ textAlign: "center" }}>
              Use UP / DOWN arrow keys to search faster. This will help you to
              keep your eyes centered to the screen while doing a viva session
            </span>
            <span
              style={{
                textAlign: "center",
                fontWeight: "normal",
                color: "red",
              }}
            >
              NOTE : this component in not finished
            </span>
          </span>
        );
      },
      actionBefore: async () => {
        await timeout(5000);
      },
    },
    {
      stepInteraction: false,
      selector: '[data-tut="add_q_button"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🗳 Add Q&A's.
            </span>
            <span style={{ textAlign: "center" }}>
              Tap on this button in order to add a question
            </span>
          </span>
        );
      },
    },

    {
      stepInteraction: false,
      selector: '[data-tut="viva_voice"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🗣 Voice Recognition
            </span>
            <span style={{ textAlign: "center" }}>
              We’ve got voice recognition feature too, it can detect voice from
              your microphone, convert them into text then search through your
              added questions.
            </span>
          </span>
        );
      },
      action: () => {
        setValue(1);
      },
    },
    {
      stepInteraction: false,
      selector: '[data-tut="viva-voice-buttons"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🎙 Microphone Controls
            </span>
            <span style={{ textAlign: "center" }}>
              Turn On mic / Turn OFF mic and Clear previous words functions are
              done by these buttons respectively
            </span>
          </span>
        );
      },
    },
    {
      action: () => {
        setIsTourOpen(false);
        setisNewUserViva(false);
        localStorage.setItem("viva", JSON.stringify("dones"));
      },
      stepInteraction: false,
      selector: "",
      content: "Thats it for Search!!",
    },
  ];
  const accentColor = "linear-gradient(to right, #1c8f9e, #5cb7b7)";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <span className="warning-viva">
        this component is still under development, send a message if you got any
        ideas
      </span>
      <AppBar
        style={{
          borderRadius: "20px",
          width: "50%",
          marginTop: "30px",
          marginBottom: "-40px",
        }}
        position="relative"
        color="default"
      >
        <Tabs
          style={{ borderRadius: "20px", backgroundColor: "white" }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Flashcards" {...a11yProps(0)} icon={<DashboardIcon />} />
          <Tab
            data-tut="viva_voice"
            label="Voice Recognition"
            {...a11yProps(1)}
            icon={<RecordVoiceOverIcon />}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        style={{
          width: "800px",

          marginTop: "50px",
          backgroundColor: " #f7f7f7",
        }}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <FlashCards />
        </TabPanel>
        <TabPanel
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          value={value}
          index={1}
          dir={theme.direction}
        >
          <SpeachRecognition />
        </TabPanel>
      </SwipeableViews>
      <Tour
        showCloseButton={false}
        accentColor={accentColor}
        rounded={5}
        steps={steps}
        isOpen={isTourOpen}
      />
    </div>
  );
}
