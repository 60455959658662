import React from "react";
import Navbar from "../common/Navbar/Navbar";
import Footer from "../common/footer/Footer";
export default function DashboardLayout(props) {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Navbar />
      {props.children}
      <Footer />
    </div>
  );
}
