import React from "react";
import ColoredLine from "../../../common/ColoredLine";
import SearchResultChart from "./SearchResultChart";
import "../SearchEngine.css";
export default function SeachResultInfo(props) {
  var items = "";
  const colorsTop = ["#75B99C", "#E78C5C", "#8792C2"];
  if (props.top.length < 3) {
    for (let index = 0; index <= 8 - props.top.length; index++) {
      props.top.push({
        lecture_name: "no document",
        no_of_slides: 0,
      });
    }
    items = props.top.slice(0, 3).map((doc, index) => {
      return (
        <div className="search-result-stats-info-row">
          <span className="search-result-stats-info-1">
            <span>{doc.lecture_name}</span>
          </span>
          <span className="search-result-stats-info-2">
            <div
              style={{
                display: "flex",
                flexDirection: "coulmn",
                justifyContent: "space-between",
                alignItems: "center",
                width: "40px",
              }}
            >
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  marginRight: "5px",
                  backgroundColor: colorsTop[index],
                  borderRadius: "20px",
                }}
              ></div>
              <div> {doc.no_of_slides}</div>
            </div>
          </span>
        </div>
      );
    });
  } else {
    items = props.top.slice(0, 3).map((doc, index) => {
      return (
        <div className="search-result-stats-info-row">
          <span className="search-result-stats-info-1">
            {" "}
            <span>{doc.lecture_name}</span>
          </span>
          <span className="search-result-stats-info-2">
            <div
              style={{
                display: "flex",
                flexDirection: "coulmn",
                justifyContent: "space-between",
                alignItems: "center",
                width: "40px",
              }}
            >
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  marginRight: "5px",
                  backgroundColor: colorsTop[index],
                  borderRadius: "20px",
                }}
              ></div>
              <div> {doc.no_of_slides}</div>
            </div>
          </span>
        </div>
      );
    });
  }

  return (
    <div className="search-result-stats">
      <div className="search-result-stats-title-wrapper">
        <span className="search-result-stats-title">Statistics</span>
      </div>
      <ColoredLine color="#C9C9C9" width="94%"></ColoredLine>
      <div className="search-result-stats-time-wrapper">
        <span className="search-result-stats-time">
          Ovarall Subject Information
        </span>
        <span style={{ fontSize: "14px" }}>Count</span>
      </div>

      <div className="search-result-stats-info">
        <div className="search-result-stats-info-row">
          <span className="search-result-stats-info-1">Total Documents</span>
          <span className="search-result-stats-info-2">
            {props.info.total_documents}
          </span>
        </div>
        <div className="search-result-stats-info-row">
          <span className="search-result-stats-info-1">Total Slides</span>
          <span className="search-result-stats-info-2">
            {props.info.total_slides}
          </span>
        </div>
      </div>

      <div className="search-result-stats-chart-wrapper"></div>
      <div className="search-result-stats-bottom-info">
        <span className="search-result-stats-bottom-info-title">
          Top 3 Documents
        </span>
        <div className="search-result-stats-info-bottom">{items}</div>
      </div>
    </div>
  );
}
