import "./UploadDashboard.css";
import React, { useRef, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ColoredLine from "../../common/ColoredLine";
import SubjectItem from "./SubjectItem";
import AddSubject from "./AddSubject";
import CircularProgress from "@material-ui/core/CircularProgress";
import server from "../../../assets/server.png";
import empty from "../../../assets/empty.png";
import { useStoreActions, useStoreState } from "easy-peasy";
import Feedback from "../../feedback/Feedback";
import ChatIcon from "@material-ui/icons/Chat";
import { motion } from "framer-motion";
import firebase from "firebase";
import Tour from "reactour";
const accentColor = "linear-gradient(to right, #1c8f9e, #5cb7b7)";
export default function UploadDashboard() {
  const getSubjects = useStoreActions((actions) => actions.getSubjects);
  const subjectsData = useStoreState((state) => state.subjectsData);
  const isNewUserUpload = useStoreState((state) => state.isNewUserUpload);

  const addSubjectToDb = useStoreActions((actions) => actions.addSubjectToDb);
  const addQuestion = useStoreActions((actions) => actions.addQuestion);

  const setisNewUserUpload = useStoreActions(
    (actions) => actions.setisNewUserUpload
  );
  const setisNewUserSearch = useStoreActions(
    (actions) => actions.setisNewUserSearch
  );
  const setisNewUserViva = useStoreActions(
    (actions) => actions.setisNewUserViva
  );
  const serverStatus = useStoreState((state) => state.serverStatus);
  const userData = useStoreState((state) => state.userData);
  const [isTourOpen, setIsTourOpen] = useState(
    isNewUserUpload === "done" || isNewUserUpload === false ? false : true
  );
  const childRef = useRef();
  const [feedback, setFeedback] = useState(false);
  const timeout = (ms) => new Promise((res) => setTimeout(res, ms));

  const steps = [
    {
      selector: "",
      stepInteraction: false,
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🎉 Welcome!
            </span>
            <span style={{ textAlign: "center" }}>
              Well, let’s see how this works in few steps! 🔥
            </span>
          </span>
        );
      },
    },
    {
      stepInteraction: false,
      selector: '[data-tut="reactour__logo"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              📚 Adding Subjects!
            </span>
            <span style={{ textAlign: "center" }}>
              In order to add subjects, simply tap on this button. Not now after
              the walkthrough is over 😌
            </span>
          </span>
        );
      },

      action: () => {
        childRef.current.handleOpen();
      },
    },

    {
      stepInteraction: false,
      selector: '[data-tut="upload_zone"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              {" "}
              📌 Reminder!
            </span>
            <span>
              You can only upload{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                12 files at a time
              </span>{" "}
              . If you got more, upload the rest once the first 12 files are
              done. Plus we only support{" "}
              <span style={{ fontWeight: "bold", color: "blue" }}>PDF</span> and{" "}
              <span style={{ fontWeight: "bold", color: "blue" }}>
                {" "}
                Powerpoint (PPTX){" "}
              </span>
              files only.,
            </span>
          </span>
        );
      },
    },
    {
      stepInteraction: false,
      selector: '[data-tut="upload_inputs"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🖋 Naming your Subjects
            </span>
            <span style={{ textAlign: "center" }}>
              You got to enter the subject which the uploaded files belongs
              to.... If the subject is already added, simply select the subject
              from the drop down
            </span>
          </span>
        );
      },
    },
    {
      action: () => {
        childRef.current.handleClose();
      },
      stepInteraction: false,
      selector: '[data-tut="subject_item"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🍾 Nicee!
            </span>
            <span style={{ textAlign: "center" }}>
              Well done! You have successfully uploaded the subject contents
            </span>
          </span>
        );
      },
    },
    {
      stepInteraction: false,
      selector: '[data-tut="navbar-search"]',
      content: function DemoHelperComponent() {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", marginBottom: "10px" }}>
              🔎 Next Step!
            </span>
            <span style={{ textAlign: "center" }}>
              Now you can got to SEARCH
            </span>
          </span>
        );
      },
    },
    {
      action: () => {
        setIsTourOpen(false);
        setisNewUserUpload(false);
        localStorage.setItem("upload", JSON.stringify("dones"));
      },
      stepInteraction: false,
      selector: "",
      content: "Thats it for Search!!",
    },
  ];

  useEffect(() => {
    const upload = JSON.parse(localStorage.getItem("upload"));
    const search = JSON.parse(localStorage.getItem("search"));
    const viva = JSON.parse(localStorage.getItem("viva"));

    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          if (upload !== "dones") {
            setisNewUserUpload(
              firebase.auth().currentUser.metadata.creationTime ===
                firebase.auth().currentUser.metadata.lastSignInTime
            );
            if (
              firebase.auth().currentUser.metadata.creationTime ===
              firebase.auth().currentUser.metadata.lastSignInTime
            ) {
              let payload = {
                user_id: user.uid,
                subject_name: "Sample Subject",
                data: [
                  {
                    lecture_name: "01: Lecture One",
                    slides: [
                      "Sample Slide 1",
                      "Sample Slide 2",
                      "Sample Slide 3",
                    ],
                  },
                ],
              };

              let payload2 = {
                user_id: user.uid,
                data: {
                  question: "Sample Question ",
                  answer: "this is the answer for question ",
                },
              };
              addSubjectToDb(payload);

              for (let index = 0; index < 3; index++) {
                addQuestion(payload2);
              }
            }
          }
          if (search !== "dones") {
            setisNewUserSearch(
              firebase.auth().currentUser.metadata.creationTime ===
                firebase.auth().currentUser.metadata.lastSignInTime
            );
          }
          if (viva !== "dones") {
            setisNewUserViva(
              firebase.auth().currentUser.metadata.creationTime ===
                firebase.auth().currentUser.metadata.lastSignInTime
            );
          }
          getSubjects(user.uid);
        }
      });
    return () => unregisterAuthObserver();
  }, []);

  let subjectNameArray = [];

  if (subjectsData != null) {
    subjectNameArray = subjectsData.map((subject) => {
      return {
        value: subject.subject_name,
        label: subject.subject_name,
      };
    });
    subjectNameArray.unshift({
      value: "None",
      label: "None",
    });
  }
  function toggleModal() {
    setFeedback(!feedback);
  }

  return (
    <motion.div
      className="main-upload "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="main-upload-canvas">
        <div className="button-container">
          <Feedback modal={feedback} toggle={toggleModal} />
          <Button
            startIcon={<ChatIcon />}
            onClick={() => setFeedback(true)}
            style={{
              marginTop: "9px",
              boxShadow: "none",
              position: "fixed",
              bottom: "15px",
              right: "15px",
              backgroundColor: "#FFF",
              borderRadius: "100px",
              boxShadow: "0px 0 1px 0.1rem rgba(94,43,250,.25)",
            }}
            variant="contained"
          >
            Feedback
          </Button>
          <Button
            data-tut="reactour__logo"
            disabled={serverStatus === "OK" ? false : true}
            onClick={() => {
              isNewUserUpload === true
                ? setIsTourOpen(true)
                : childRef.current.handleOpen();
            }}
            variant="contained"
            style={{
              borderRadius: 20,
              backgroundColor: serverStatus === "OK" ? "#5E2BFA" : "#B5B5B5",
              padding: "10px 96px",
              fontSize: "18px",
              color: "white",
            }}
          >
            <AddCircleIcon />
            <span className="button-text">Add New Subject</span>
          </Button>
        </div>
        <ColoredLine color="#C9C9C9" width="600px"></ColoredLine>
        {subjectsData === null ? (
          <div style={{ marginTop: "50px" }}>
            <CircularProgress
              size={100}
              thickness={3}
              style={{ color: "#5E2BFA" }}
            />
          </div>
        ) : serverStatus === "BAD" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={server} style={{ width: "20%", marginTop: "30px" }}></img>
            <span className="progress-text">
              internal server error, please try again
            </span>
          </div>
        ) : subjectsData.length === 0 || subjectsData === undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              draggable={false}
              src={empty}
              style={{ width: "20%", marginTop: "30px" }}
            ></img>
            <span style={{ color: "#9D9FA0" }} className="progress-text">
              no subjects found, upload now
            </span>
          </div>
        ) : (
          <div className="subject-items-wrapper">
            {subjectsData.map((subject) => (
              <SubjectItem subject={subject} />
            ))}
          </div>
        )}

        <AddSubject subjectNames={subjectNameArray} ref={childRef} />
      </div>
      <Tour
        accentColor={accentColor}
        rounded={5}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        showCloseButton={false}
      />
    </motion.div>
  );
}
