import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import logoImg from "../../../assets/openbook.png";
import "./Navbar.css";
import Badge from "@material-ui/core/Badge";
import user from "../../../assets/user.png";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "react-modal";
const headersData = [
  {
    label: "Upload",
    href: "/upload",
  },
  {
    label: "Search",
    href: "/search",
  },
  {
    label: "Online Viva",
    href: "/vivas",
  },
];

const useStyles = makeStyles(() => ({
  header: {
    paddingRight: "79px",
    paddingLeft: "118px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const serverStatus = useStoreState((state) => state.serverStatus);
  const signOut = useStoreActions((actions) => actions.signOut);
  const userData = useStoreState((state) => state.userData);

  const img = userData.photoURL === null ? user : userData.photoURL;
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();

  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: serverStatus === "OK" ? "#44b700" : "#c9c9c9",
      color: serverStatus === "OK" ? "#44b700" : "#c9c9c9",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "static",
        top: -1,
        left: -1,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }))(Badge);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar
        className={toolbar}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {femmecubatorLogo}
        <div data-tut="navbar-search">{getMenuButtons()}</div>
        <div
          style={{
            width: "160px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "130px",
            marginRight: "20px",
          }}
        >
          <StyledBadge
            style={{
              cursor: "pointer",
            }}
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
            onClick={() => setIsOpen(true)}
          >
            <Avatar
              style={{
                width: "4vh",
                height: "4vh",
              }}
              draggable={false}
              alt="Remy Sharp"
              src={img}
            />
          </StyledBadge>
          <Modal
            className="account-details-modal"
            overlayClassName="myoverlay"
            isOpen={isOpen}
            onRequestClose={() => {
              setIsOpen(false);
            }}
          >
            <div className="account-details">
              <span
                style={{
                  cursor: "pointer",
                  color: "red",
                  fontFamily: "Quicksand",
                  fontSize: "18px",
                }}
                onClick={() => signOut()}
              >
                Logout
              </span>
            </div>
          </Modal>
          <Typography>
            <span
              style={{
                fontWeight: "bold",
                fontFamily: "Source Sans Pro",
                fontSize: "20px",
              }}
            >
              Greetings!
            </span>
          </Typography>
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar style={{ display: "flex", justifyContent: "space-around" }}>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <StyledBadge
          style={{
            cursor: "pointer",
          }}
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
          onClick={() => setIsOpen(true)}
        >
          <Avatar
            style={{
              width: "3vh",
              height: "3vh"
            }}
            draggable={false}
            alt="Remy Sharp"
            src={img}
          />
        </StyledBadge>
        <Modal
          className="account-details-modal"
          overlayClassName="myoverlay"
          isOpen={isOpen}
          onRequestClose={() => {
            setIsOpen(false);
          }}
        >
          <div className="account-details">
            <span
              style={{
                cursor: "pointer",
                color: "red",
                fontFamily: "Quicksand",
                fontSize: "18px",
              }}
              onClick={() => signOut()}
            >
              Logout
            </span>
          </div>
        </Modal>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const femmecubatorLogo = (
    <Typography variant="h6" component="h1" className={logo}>
      <img
        style={{ marginTop: "10px" }}
        draggable={false}
        src={logoImg}
        width="240vh"
      ></img>
    </Typography>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
            className: menuButton,
          }}
        >
          <span className="link-text">{label}</span>
        </Button>
      );
    });
  };

  return (
    <header>
      <AppBar
        position="static"
        color="#FFFFFF"
        elevation={0}
        className={header}
      >
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
