import "./App.css";
import UploadDashboard from "./components/dashboards/upload-dashboard/UploadDashboard";
import SearchEngine from "./components/dashboards/search-engine/SearchEngine";
import Home from "./pages/Home";
import HowTo from "./pages/HowTo";
import DashboardLayout from "./components/layout/DashboardLayout";
import Vivas from "./components/dashboards/viva-dashboard/Vivas";

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { useStoreRehydrated } from "easy-peasy";
import { AnimatePresence, motion } from "framer-motion";
import { useStoreActions, useStoreState } from "easy-peasy";
import PrivateRoute from "./PrivateRoute";
function App() {
  const isRehydrated = useStoreRehydrated();
  return <div style={{}}>{isRehydrated ? <RouterMain /> : null}</div>;
}
// router 1
function RouterMain() {
  return (
    <Router>
      <AnimatePresence>
        <Switch>
          <PublicRoute restricted={true} path="/" exact component={Home} />

          <PrivateRouteWrapper
            path="/howto"
            component={HowTo}
            layout={DashboardLayout}
          />
          <PrivateRouteWrapper
            path="/upload"
            component={UploadDashboard}
            layout={DashboardLayout}
          />
          <PrivateRouteWrapper
            path="/search"
            component={SearchEngine}
            layout={DashboardLayout}
          />
          <PrivateRouteWrapper
            path="/vivas"
            component={Vivas}
            layout={DashboardLayout}
          />
        </Switch>
      </AnimatePresence>
    </Router>
  );
}

function PrivateRouteWrapper({
  component: Component,
  layout: Layout,
  ...rest
}) {
  const isLogged = useStoreState((state) => state.isLogged);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const isLogged = useStoreState((state) => state.isLogged);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged && restricted ? (
          <Redirect to="/upload" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default App;
