import React, { useState } from "react";
import "../Vivas.css";
import Modal from "react-modal";
import Done from "./Done";
import Question from "./Question";
import BackupIcon from "@material-ui/icons/Backup";
import HelpIcon from "@material-ui/icons/Help";
export default function AddVivas(props) {
  const [window, setWindow] = useState("menu");

  return (
    <Modal
      className="mymodalviva"
      overlayClassName="myoverlay"
      isOpen={props.modal}
      onRequestClose={props.toggle}
    >
      <div className="viva-add-main">
        {window === "menu" ? (
          <div className="viva-add-wrapper">
            <span className="viva-add-title">Adding new question cards?</span>
            <div className="viva-add-items">
              <div onClick={() => setWindow("issue")} className="viva-add-item">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <HelpIcon
                    style={{ width: "50px", height: "50px", color: "#2ecc71" }}
                  />
                  Manual
                </div>
              </div>
              <div className="viva-add-item">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BackupIcon
                    style={{ width: "50px", height: "50px", color: "#F0BC2E" }}
                  />
                  Upload CSV
                  <span style={{ fontSize: "10px", color: "red" }}>
                    not available yet
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : window === "other" ? (
          <span>haven't finished yet</span>
        ) : window === "done" ? (
          <Done toggle={setWindow} />
        ) : (
          <Question
            toggle={setWindow}
            title="Add New Question"
            icon={
              <HelpIcon
                style={{ width: "20px", height: "20px", color: "#2ecc71" }}
              />
            }
          />
        )}
      </div>
    </Modal>
  );
}
