import axios from "axios";

export const addSubject = (payload) => {
  return axios
    .post("https://api.openexam.live/api/upload/upload_gcs", payload, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getSubjects = (payload) => {
  return axios
    .post("https://api.openexam.live/api/subject/getSubjects", payload, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const addSubjectToDb = (payload) => {
  return axios
    .post("https://api.openexam.live/api/subject/addSubject", payload, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteSubject = (payload) => {
  return axios
    .post("https://api.openexam.live/api/subject/deleteSubjects", payload, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const addFeedback = (payload) => {
  return axios
    .post("https://api.openexam.live/api/feedback/addFeedback", payload, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const addQuestion = (payload) => {
  return axios
    .post("https://api.openexam.live/api/questions/addQuestions", payload, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getQuestions = (payload) => {
  return axios
    .post("https://api.openexam.live/api/questions/getQuestions", payload, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteQuestion = (payload) => {
  return axios
    .post(
      "https://api.openexam.live/api/questions/deleteQuestions",
      payload,
      {}
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteLecture = (payload) => {
  return axios
    .post("https://api.openexam.live/api/subject/deleteLecture", payload, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const renameLecture = (payload) => {
  return axios
    .post("https://api.openexam.live/api/subject/renameLecture", payload, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
