import React, { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Fab from "@material-ui/core/Fab";
import Siriwave from "react-siriwave";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AudioResult from "./AudioResult";
import { useWindowSize } from "../../../../Hooks/useWindowSize";
import { useStoreActions, useStoreState } from "easy-peasy";
const Dictaphone = () => {
  const size = useWindowSize();
  const [length, setLength] = useState(4);
  const [searchInput, setSearchInput] = useState("");
  const questions = useStoreState((state) => state.questions);
  const [searchResult, setSearchResult] = useState(questions);

  const [isMicPressed, setIsMicPressed] = useState(false);

  const commands = [
    {
      command: "*clear this*",
      callback: () => resetTranscript(),
    },
    {
      command: "*cancel microphone*",
      callback: () => stop(),
    },
  ];

  const {
    transcript,
    resetTranscript,
    interimTranscript,
    finalTranscript,
  } = useSpeechRecognition({});

  useEffect(() => {
    if (transcript.length > 5) {
      setLength(5);
    } else {
      setLength(transcript.length + 2);
    }
    if (transcript.length > 0) {
      search(transcript);
    }
    setTimeout(() => {
      setLength(2);
    }, 2000);
  }, [transcript]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return <h4>Sorry, this browser doesn't support this feature 😌</h4>;
  }

  const start = () => {
    SpeechRecognition.startListening({ continuous: true });
    setIsMicPressed(true);
    setLength(2.5);
  };

  const stop = () => {
    setIsMicPressed(false);
    SpeechRecognition.stopListening();
    setLength(0.5);
  };

  const clear = () => {
    resetTranscript();
  };

  const search = (searched) => {
    let resultArr = [];

    questions.map((data, index) => {
      let keyword = searched.toLocaleLowerCase().replace(/ /g, "");
      let content_lower = data.question.toLowerCase().replace(/ /g, "");
      let myReg = new RegExp(".*" + keyword + ".*");
      if (content_lower.match(myReg)) {
        let result = {
          question: data.question,
          answer: data.answer,
        };

        resultArr.push(result);
      }
    });

    setSearchResult(resultArr);
  };
  const x = size.height === undefined ? 50 : size.height * 0.085;
  console.log(x);

  const renderResults = searchResult.map((question, index) => {
    return <AudioResult question={question} />;
  });

  return (
    <div className="vivas-audio-wrapper">
      <div style={{ display: "flex", justifyContent: "center" }}>
        {size.height === undefined ? null : (
          <Siriwave style="ios9" amplitude={length} width={300} height={x} />
        )}
      </div>
      <div className="vivas-audio-buttons" data-tut="viva-voice-buttons">
        <div
          style={{
            width: "300px",
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            height: "58px",
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            style={{ backgroundColor: isMicPressed ? "#ff5252" : "white" }}
            onClick={() => {
              start();
            }}
          >
            <MicIcon
              style={{
                height: "30px",
                width: "30px",
                color: isMicPressed ? "white" : "#ff5252",
              }}
            />
          </Fab>
          <Fab
            onClick={() => {
              stop();
            }}
            color="primary"
            aria-label="add"
            style={{ backgroundColor: isMicPressed ? "white" : "black" }}
          >
            <MicOffIcon
              style={{
                height: "30px",
                width: "30px",
                color: isMicPressed ? "black" : "white",
              }}
            />
          </Fab>
          <Fab
            onClick={() => clear()}
            color="primary"
            aria-label="add"
            style={{ backgroundColor: "#2196f3" }}
          >
            <RemoveCircleOutlineIcon
              style={{ height: "30px", width: "30px", color: "white" }}
            />
          </Fab>
        </div>
      </div>

      <div className="vivas-voice-search">
        <div
          style={{
            width: "400px",
            whiteSpace: "nowrap",
          }}
          className="vivas-voice-search-text"
        >
          {transcript.substring(transcript.length - 60, transcript.length)}

          {isMicPressed ? <div>....</div> : null}
        </div>
      </div>
      <div className="viva-sesult-wrapper">{renderResults}</div>
    </div>
  );
};
export default Dictaphone;
