import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import ColoredLine from "../../common/ColoredLine";
import DeleteIcon from "@material-ui/icons/Delete";
import { useStoreActions, useStoreState } from "easy-peasy";
export default function VivaQuizCard(props) {
  const deleteQuestion = useStoreActions((actions) => actions.deleteQuestion);
  const userData = useStoreState((state) => state.userData);

  const deleteQuestions = () => {
    const payload = {
      user_id: userData.uid,
      question_id: props.question._id,
    };

    deleteQuestion(payload);
  };
  return (
    <SplideSlide>
      <div
        data-tut="viva_qs"
        className={
          props.isColor ? "viva-item-wrapper-active" : "viva-item-wrapper"
        }
      >
        <div className="viva-item-title">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span className="viva-item-title-question">
              {props.question.question}
            </span>
            <DeleteIcon
              style={{
                color: "#ff5252",
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => deleteQuestions()}
            />
          </div>

          <ColoredLine
            color={props.isColor ? "#616069" : "#C9C9C9"}
            width="98%"
          ></ColoredLine>
          <span className="viva-item-title-answer">
            {props.question.answer}
          </span>
        </div>
      </div>
    </SplideSlide>
  );
}
