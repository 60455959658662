import React from "react";
import ColoredLine from "../../common/ColoredLine";
import Highlighter from "react-highlight-words";
import "./SearchEngine.css";
export default function SeachResult(props) {
  return (
    <div className="search-result-item">
      <div className="search-result-item-wrapper">
        <div className="search-result-item-title">
          <span className="search-result-item-subject">
            {props.lectureName}
          </span>
          <span className="search-result-item-page-no">
            Slide :
            <span className="search-result-item-page-no-box">
              {props.slideno}
            </span>
          </span>
        </div>
        <ColoredLine color="#C9C9C9" width="100%"></ColoredLine>
        <div className="search-result-item-text-wrapper">
          <div className="search-result-item-status"> </div>
          <span className="search-result-item-text">
            <Highlighter
              highlightClassName="testing-this"
              highlightStyle={{ backgroundColor: "#2ecc71" }}
              searchWords={props.searchTerm}
              textToHighlight={props.slideData}
            />
          </span>
        </div>
      </div>
    </div>
  );
}
