const ColoredLine = ({ color, width }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
      width: width,
      borderWidth: "0px",
    }}
  />
);

export default ColoredLine;
