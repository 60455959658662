import React from "react";
import progress from "../../../assets/check-circle.gif";
import error from "../../../assets/error.png";
import "./UploadDashboard.css";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function UploadProgress(props) {
  const classes = useStyles();
  return props.type == "loading" ? (
    <div className="progress-main">
      <CircularProgress size={100} thickness={5} style={{ color: "#5BC15B" }} />
      <span className="progress-text">uploading your files...</span>
    </div>
  ) : props.type == "error" ? (
    <div className="progress-main">
      <ErrorIcon
        style={{ color: "#ff5252", width: "100px", height: "100px" }}
      />
      <span className="progress-text">error, please try again...</span>
    </div>
  ) : props.type == "storing" ? (
    <div className="progress-main">
      <CircularProgress size={100} thickness={5} style={{ color: "#5BC15B" }} />
      <span className="progress-text">setting files on database...</span>
    </div>
  ) : (
    <div className="progress-main">
      <CheckCircleIcon
        style={{ color: "#5BC15B", width: "100px", height: "100px" }}
      />
      <span className="progress-text">All done!</span>
    </div>
  );
}
