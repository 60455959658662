import React, { useState } from "react";
import "../Vivas.css";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useStoreActions, useStoreState } from "easy-peasy";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
    },
  },
}));
export default function Question(props) {
  const classes = useStyles();
  const [issues, setIssues] = useState("");
  const [answer, setAnswer] = useState("");
  const addQuestion = useStoreActions((actions) => actions.addQuestion);
  const userData = useStoreState((state) => state.userData);

  const onTextChangeIssue = (e) => {
    setIssues(e.target.value);
  };

  const onTextChangeAnswer = (e) => {
    setAnswer(e.target.value);
  };

  const submitFeedback = () => {
    const payload = {
      user_id: userData.uid,
      data: {
        question: issues,
        answer: answer,
      },
    };

    addQuestion(payload);
    props.toggle("done");
  };

  return (
    <div className="feedback-wrapper-issues-main">
      <div className="feedback-wrapper-issues">
        <ArrowBackIcon
          style={{ color: "grey", cursor: "pointer" }}
          onClick={() => props.toggle("menu")}
        />
        <span className="feedback-title">
          {props.icon}
          {props.title}
        </span>
        <CloseIcon
          style={{ color: "grey", cursor: "pointer" }}
          onClick={() => props.toggle("menu")}
        />
      </div>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          onChange={(e) => onTextChangeIssue(e)}
          className="inputRounded"
          placeholder="question... max characters 40"
          autoFocus
          style={{ width: "100%", borderWidth: "10px" }}
          id="outlined-basic"
          variant="outlined"
          multiline
          rows={1}
          value={issues}
          name="issues"
          inputProps={{ maxLength: 40 }}
        />
        <TextField
          onChange={(e) => onTextChangeAnswer(e)}
          className="inputRounded"
          placeholder="answer... max characters 70"
          style={{ width: "100%", borderWidth: "10px", marginTop: "10px" }}
          id="outlined-basic"
          variant="outlined"
          multiline
          rows={3}
          value={answer}
          name="answer"
          inputProps={{ maxLength: 75 }}
        />
      </form>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button
          disabled={issues.length >= 1 && answer.length >= 1 ? false : true}
          style={{
            boxShadow: "none",
            marginTop: "9px",
            borderRadius: "20px",
          }}
          variant="contained"
          color="secondary"
          onClick={submitFeedback}
        >
          Save
        </Button>
        {/* <Button
          disabled={issues.length >= 1 && answer.length >= 1 ? false : true}
          style={{
            boxShadow: "none",
            marginTop: "9px",
            borderRadius: "20px",
            marginLeft: "20px",
          }}
          variant="contained"
          color="primary"
          onClick={submitFeedback}
        >
          Add Another
        </Button> */}
      </div>
    </div>
  );
}
