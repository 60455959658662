import React from "react";
import "../Vivas.css";

export default function AudioResult(props) {
  return (
    <div className="viva-result-item">
      <div style={{ marginLeft: "10px", marginTop: "5px" }}>
        <span style={{ fontFamily: "Quicksand" }}>
          {props.question.question}
        </span>
      </div>
      <div
        style={{
          marginLeft: "10px",
          margin: "10px",
          height: "40%",
          overflow: "hidden",
          color: "#3c3737",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span style={{ fontFamily: "Quicksand", fontSize: "15px" }}>
          {props.question.answer}
        </span>
      </div>
    </div>
  );
}
