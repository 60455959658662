import React, {
  useCallback,
  useMemo,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDropzone } from "react-dropzone";
import uploadImage from "../../../assets/upload.png";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ViewFiles from "./ViewFiles";
import UploadProgress from "./UploadProgress";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Tooltip from "@material-ui/core/Tooltip";
import { useStoreActions, useStoreState } from "easy-peasy";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 100,
    fontSize: "12px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
    outline: "none",
    position: "relative",
    width: "600px",
    overflow: "hidden",
  },
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 3,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#898989",
  outline: "none",
  borderRadius: "10px",
  transition: "border .24s ease-in-out",
  boxShadow:
    "0px 0px 7px 1px rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.15)",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const AddSubject = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [subjectText, setSubjectText] = useState("");
  const [subjectSelect, setSubjectSelect] = useState("None");
  const [status, setStatus] = useState("");

  const addSubjects = useStoreActions((actions) => actions.addSubjects);
  const isLoading = useStoreState((state) => state.isLoading);
  const uploadStatus = useStoreState((state) => state.uploadStatus);
  const isModalOpen = useStoreState((state) => state.isModalOpen);
  const setModalOpen = useStoreActions((actions) => actions.setModalOpen);
  const userData = useStoreState((state) => state.userData);
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleChange = (event) => {
    setSubjectSelect(event.target.value);
  };

  const onTextChange = (e) => {
    setSubjectText(e.target.value);
  };

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 12,
    accept:
      "application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation",
    maxSize: 3145728,
  });
  const isFileTooLarge =
    fileRejections.length > 0 && fileRejections[0].size > 1;

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setModalOpen(true);
      setIsUploading(false);
    },
    handleClose() {
      setModalOpen(false);
    },
  }));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.size > 1 ? `${file.path} - ${file.size} bytes` : null}

        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  const HandleUpload = () => {
    var formData = new FormData();
    let subjectName = subjectText === "" ? subjectSelect : subjectText;
    for (const key of Object.keys(acceptedFiles)) {
      formData.append("fileCollection", acceptedFiles[key]);
      formData.append("folder", subjectName);
    }

    const payload = {
      formData: formData,
      user_id: userData.uid,
    };

    addSubjects(payload);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen} data-tut="upload_files">
          <div className={classes.paper}>
            {isLoading ? (
              <UploadProgress type={uploadStatus} />
            ) : (
              <React.Fragment>
                {" "}
                <ViewFiles
                  show={drawerOpen}
                  files={acceptedFiles}
                  rejections={fileRejections}
                />
                <div
                  onClick={drawerOpen ? () => setDrawerOpen(!drawerOpen) : null}
                  className={
                    drawerOpen
                      ? "add-subject-main-inactive"
                      : "add-subject-main"
                  }
                >
                  <h2 id="transition-modal-title">
                    <span className="add-subject-title">
                      Upload your Material
                    </span>
                  </h2>
                  <section
                    className={drawerOpen ? "container inactive" : "container"}
                  >
                    <div {...getRootProps({ style })} data-tut="upload_zone">
                      <input {...getInputProps()} />
                      <img
                        style={{ opacity: 0.5 }}
                        width="200x"
                        height="200px"
                        src={uploadImage}
                      ></img>
                      <p>
                        drag 'n' drop some PDF or PTT files here, or click to
                        select files
                      </p>
                      <span>
                        maximum 12 files at a time, each file should be less
                        than 2.5mb
                      </span>
                    </div>
                  </section>
                  <div className="add-file-status">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        variant="contained"
                        disabled={
                          acceptedFiles.length === 0 &&
                          fileRejections.length === 0
                            ? true
                            : false
                        }
                        startIcon={<VisibilityIcon />}
                        style={{
                          boxShadow: "none",
                          borderRadius: 10,
                          backgroundColor:
                            acceptedFiles.length === 0 &&
                            fileRejections.length === 0
                              ? "#B9ACE2"
                              : "#5e2bfa",
                          padding: "6px 20px",
                          color: "white",
                          marginRight: "10px",
                        }}
                      >
                        <span className="button-text-item">
                          {fileRejections.length === 0
                            ? `${acceptedFiles.length} ${
                                acceptedFiles.length == 1 ? "File" : "Files"
                              } Ready`
                            : `${acceptedFiles.length} ${
                                acceptedFiles.length == 1 ? "File" : "Files"
                              } Ready and ${fileRejections.length} Errors`}
                        </span>
                      </Button>
                      {fileRejections.length > 0 ? (
                        <Tooltip
                          classes={{ tooltip: classes.customWidth }}
                          title="some files have errors, press the left button for more details"
                        >
                          <ErrorOutlineIcon
                            style={{ color: "#ff5252" }}
                          ></ErrorOutlineIcon>
                        </Tooltip>
                      ) : null}
                    </div>
                  </div>
                  <div className="subject-submit-form" data-tut="upload_inputs">
                    <div className="add-subject-textfields">
                      <TextField
                        inputProps={{ maxLength: 16 }}
                        error={
                          subjectText.length > 0 && subjectText.length < 4
                            ? true
                            : false
                        }
                        id="outlined-error-helper-text"
                        disabled={subjectSelect == "None" ? false : true}
                        id="outlined-basic"
                        label={
                          subjectText.length > 0 && subjectText.length < 4
                            ? "[ minimum four letters ]"
                            : "New Subject"
                        }
                        variant="outlined"
                        value={subjectText}
                        onChange={(e) => onTextChange(e)}
                        name="subject"
                      />
                      <span>Or </span>
                      <TextField
                        disabled={subjectText == "" ? false : true}
                        style={{ width: "190px" }}
                        id="outlined-select-currency-native"
                        select
                        value={subjectSelect}
                        onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                      >
                        {props.subjectNames.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </div>

                    <Button
                      onClick={HandleUpload}
                      variant="contained"
                      disabled={
                        acceptedFiles.length != 0 &&
                        (((subjectText != "" || subjectSelect != "None") &&
                          subjectText.length > 3) ||
                          subjectSelect != "None")
                          ? false
                          : true
                      }
                      style={{
                        borderRadius: 10,
                        backgroundColor:
                          acceptedFiles.length != 0 &&
                          (((subjectText != "" || subjectSelect != "None") &&
                            subjectText.length > 3) ||
                            subjectSelect != "None")
                            ? "#62BF71"
                            : "#B5B5B5",

                        padding: "6px 20px",

                        color: "white",
                      }}
                    >
                      <span className="button-text-item">Upload Lectures</span>
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
});

export default AddSubject;
