import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import SaveIcon from "@material-ui/icons/Save";
import "./UploadDashboard.css";

import { useStoreActions, useStoreState } from "easy-peasy";
export default function LectureList(props) {
  const [fileName, setfileName] = useState(props.lecture.lecture_name);
  const [isSelected, setisSelected] = useState(true);
  const userData = useStoreState((state) => state.userData);
  const deleteLecture = useStoreActions((actions) => actions.deleteLecture);
  const renameLecture = useStoreActions((actions) => actions.renameLecture);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      width: "80%",
      maxHeight: 435,
    },
    disabledInput: {
      color: theme.palette.text.primary,
    },
  }));

  const classes = useStyles();

  const rename = () => {
    setisSelected(false);
  };
  const update = () => {
    const payload = {
      user_id: userData.uid,
      lecture_id: props.lecture._id,
      subject_name: props.subjectname,
      new_name: fileName,
    };
    renameLecture(payload);
    setisSelected(true);
  };

  const deleteLec = () => {
    const payload = {
      user_id: userData.uid,
      lecture_id: props.lecture._id,
      subject_name: props.subjectname,
    };
    deleteLecture(payload);
  };

  return (
    <div className="subject-item-dialog-wrapper">
      <div className="subject-item-dialog-wrapper-item">
        <div className="subject-item-dialog-wrapper-text">
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            onChange={(e) => setfileName(e.target.value)}
            style={{
              width: "180px",
              backgroundColor: isSelected ? "white" : "#D9D9D9",
              borderRadius: "10px",
            }}
            disabled={isSelected}
            value={fileName}
            inputProps={{
              maxLength: 100,
            }}
            InputProps={{
              maxLength: 12,
              disableUnderline: true,
              classes: { disabled: classes.disabledInput },
            }}
          />
        </div>

        {isSelected ? (
          <Button
            onClick={() => rename()}
            style={{ boxShadow: "none", width: "100px" }}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<BorderColorIcon />}
          >
            Rename
          </Button>
        ) : (
          <Button
            onClick={() => update()}
            style={{
              boxShadow: "none",
              width: "100px",
              backgroundColor: "#63BF71",
            }}
            variant="contained"
            color="secondary"
            size="small"
            disabled={fileName.length > 0 ? false : true}
            startIcon={<SaveIcon />}
          >
            UPDATE
          </Button>
        )}

        <DeleteIcon
          style={{
            color: "#ff5252",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() => deleteLec()}
        />
      </div>
    </div>
  );
}
